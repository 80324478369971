import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './RichText.css';

function RichText({ onRichTextChange }) {
  const [editorValue, setEditorValue] = useState('');

  const handleEditorChange = (content, delta, source, editor) => {
    setEditorValue(editor.getContents());
    onRichTextChange(editor.getHTML());
  };

  return (
    <>
      <h1 className="Rheading">Write Case Remarks</h1>
      <ReactQuill
        theme="snow"
        value={editorValue}
        onChange={handleEditorChange}
        className="rich-text-editor"
      />
    </>
  )
    ;
}

export default RichText;