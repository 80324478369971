import { useCallback, useEffect, useState } from 'react'
import { Box } from '@chakra-ui/react';
import AllCasesTable from './components/AllCasesTable';
import InstructorApiService from '../../../services/InstructorApiservices';
import { useMyContext } from '../../../contexts/ContextProvider';
import Uploader from '../../admin/uploader';
import React from 'react';


export const AllCases = () => {
	
	const [allCasesData, setallCasesData] = useState([]);
	const { getId } = useMyContext();
	const fetchData = useCallback(async (userId: any) => {
		try {
			const instructorApiService = new InstructorApiService();
			let result: any = await instructorApiService.getAllCases(userId);
			result = JSON.parse(result)
			setallCasesData(result?.['data']);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	},[]);
	useEffect(() => {
		fetchData(getId);
	}, [fetchData, getId])

	return (
		<div >
			<Box pt={{ base: '130px', md: '180px', xl: '80px' }}>
				<Uploader />
				{allCasesData.length > 0 ? (
					<AllCasesTable tableData={allCasesData} />
				) : (
					<p>No Data...</p>
				)}
			</Box>
		</div>
	);
}


export default AllCases;