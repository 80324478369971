// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rich-text-editor {
 width: 100%;
 min-height: 300px;
 max-height: 450px;
 height: 350px; /* Default height */

 /* Adjust height for smaller laptop screens */
 @media (max-width: 1366px) {
   height: 290px;
 }

 /* Adjust height for larger laptop screens */
 @media (min-width: 1367px) {
   height: 450px;
 }
}

.Rheading {
 font-size: 2rem;
 margin-bottom: 20px;
 margin-top: 25px;
}`, "",{"version":3,"sources":["webpack://./src/components/richTextBox/RichText.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,iBAAiB;CACjB,iBAAiB;CACjB,aAAa,EAAE,mBAAmB;;CAElC,6CAA6C;CAC7C;GACE,aAAa;CACf;;CAEA,4CAA4C;CAC5C;GACE,aAAa;CACf;AACD;;AAEA;CACC,eAAe;CACf,mBAAmB;CACnB,gBAAgB;AACjB","sourcesContent":[".rich-text-editor {\n width: 100%;\n min-height: 300px;\n max-height: 450px;\n height: 350px; /* Default height */\n\n /* Adjust height for smaller laptop screens */\n @media (max-width: 1366px) {\n   height: 290px;\n }\n\n /* Adjust height for larger laptop screens */\n @media (min-width: 1367px) {\n   height: 450px;\n }\n}\n\n.Rheading {\n font-size: 2rem;\n margin-bottom: 20px;\n margin-top: 25px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
