import { useState } from 'react';
import { Box, Button, CircularProgress, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from '../../../../components/card/Card';
// Assets
import { MdUpload } from 'react-icons/md';
import InstructorApiService from '../../../../services/InstructorApiservices'; // Adjust the path as needed
import Dropzone from '../../profile/components/Dropzone';
import toast, { Toaster } from 'react-hot-toast';
import { useMyContext } from '../../../../contexts/ContextProvider';
import React from 'react';


export default function Upload(props: { used?: number; total?: number;[x: string]: unknown }) {
  const { ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const brandColor = useColorModeValue('brand.500', 'white');
  const textColorSecondary = 'gray.400';
  const { getId } = useMyContext();
  const [file, setFile] = useState<File | null>(null);
  const [getProgressLoading, setProgressLoader] = useState(false);

  const handleFilesAccepted = (acceptedFiles) => {
    const isXlsx = acceptedFiles[0].name.toLowerCase().endsWith('.xlsx');
    if (isXlsx) {
      setFile(acceptedFiles[0]);
    } else {
      alert('Please select a xlsx file.');
    }
  };

  const uploadFile = async () => {
    if (file) {
      setProgressLoader(true);
      const instructorApiService = new InstructorApiService();
      let result = await instructorApiService.uploadCsvFile(file, getId);
      result = JSON.parse(result)
      toast(result?.['data']);
      setProgressLoader(false);
    } else {
      setProgressLoader(false);
      toast(`Please select a CSV file before uploading.`);
    }
  };

  return (
    <>
      <Toaster />
      <Card {...rest} mb='40px' alignItems='center' p='20px'>
        {
          getProgressLoading == true ?
            <CircularProgress isIndeterminate color='green.300' marginBottom={"20px"} /> : ""
        }
        <Flex h='100%' direction={{ base: 'column', '2xl': 'row' }}>
          <Dropzone
            onFilesAccepted={handleFilesAccepted}
            w={{ base: '100%', '2xl': '268px' }}
            me='36px'
            maxH={{ base: '60%', lg: '50%', '2xl': '100%' }}
            minH={{ base: '60%', lg: '50%', '2xl': '100%' }}
            content={
              <Box>
                <Icon as={MdUpload} w='80px' h='80px' color={brandColor} />
                <Flex justify='center' mx='auto' mb='12px'>
                  <Text fontSize='xl' fontWeight='700' color={brandColor}>
                    Upload Files
                  </Text>
                </Flex>
                <Text fontSize='sm' fontWeight='500' color='secondaryGray.500'>
                  XLSX-File Only
                </Text>
              </Box>
            }
          />
          <Flex direction='column' pe='44px'>
            <Text
              color={textColorPrimary}
              fontWeight='bold'
              textAlign='start'
              fontSize='2xl'
              mt={{ base: '20px', '2xl': '50px' }}>
              Template upload your XLSX file
            </Text>
            <Text
              color={textColorSecondary}
              fontSize='md'
              my={{ base: 'auto', '2xl': '10px' }}
              mx='auto'
              textAlign='start'>
              XLSX file must have same column otherwise issue occur
            </Text>
            {/* <Flex w='100%'> */}
            <Button
              me='100%'
              mb='60px'
              w='140px'
              minW='140px'
              my={{ base: 'auto', '2xl': '10px' }}
              mx='auto'
              mt={{ base: '20px', '2xl': 'auto' }}
              variant='brand'
              onClick={() => uploadFile()}
              fontWeight='500'>
              Upload-Now
            </Button>
            {/* </Flex> */}
          </Flex>
        </Flex>
      </Card>
    </>
  );
}
