import React from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import { useHistory } from 'react-router-dom';

import {
  Box,
  Button,
  // Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import AuthService from "../../../services/AuthService";
import toast, { Toaster } from 'react-hot-toast';

interface FormState {
  email: string;
  password: string;
}
function SignIn() {
  const history = useHistory();

  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  // const [email, setEmail] = React.useState("muz@gmail.com");
  // const [password, setPassword] = React.useState("muz@gmail.com");
  const [formState, setFormState] = React.useState<FormState>({
    email: "",
    password: "",
  });
  const [errors, setErrors] = React.useState<Partial<FormState>>({});

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormState((prev) => ({ ...prev, [name]: value }));
  }
  const validate = (email: string, password: string) => {
    const tempErrors: Partial<FormState> = {};
    if (!email) tempErrors.email = "Email is required.";
    else if (!/\S+@\S+\.\S+/.test(email)) tempErrors.email = "Email is invalid.";
    if (!password) tempErrors.password = "Password is required.";
    else if (password.length < 6) tempErrors.password = "Password must be at least 6 characters.";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const submit = async () => {
    const { email, password } = formState;
    if (validate(email, password)) {
      let result
      try {
        const authService = new AuthService();
        result = await authService.login(email.toString().toLowerCase(), password);
        const parsedResult = JSON.parse(result);
        const toastMessage = typeof parsedResult.data === 'object'
          ? JSON.stringify(parsedResult.data)
          : parsedResult.data;
        sessionStorage.setItem("loginData", toastMessage);
        toast(`Successfully Login`);
        history.push('/admin');
      } catch (ex) {
        toast('Internal Server Error')
      }
    }
  };
  return (
    <>
      <Toaster />
      <DefaultAuth>
        <Flex
          maxW={{ base: "100%", md: "max-content" }}
          w='100%'
          mx={{ base: "auto", lg: "0px" }}
          me='auto'
          h='100%'
          alignItems='start'
          justifyContent='center'
          mb={{ base: "30px", md: "60px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "40px", md: "14vh" }}
          flexDirection='column'>
          <Box me='auto'>
            <Heading color={textColor} fontSize='36px' mb='10px'>
              Sign In
            </Heading>
            <Text
              mb='36px'
              ms='4px'
              color={textColorSecondary}
              fontWeight='400'
              fontSize='md'>
              Enter your email and password to sign in!
            </Text>
          </Box>
          <Flex
            zIndex='2'
            direction='column'
            w={{ base: "100%", md: "420px" }}
            maxW='100%'
            background='transparent'
            borderRadius='15px'
            mx={{ base: "auto", lg: "unset" }}
            me='auto'
            mb={{ base: "20px", md: "auto" }}>
            <FormControl isInvalid={!!errors.email}>
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px" marginTop={"5px"}>
                Email<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                placeholder="Your Email"
                name="email"
                value={formState.email}
                onChange={handleChange}
                mb="24px"
                fontWeight="500"
                size="lg"
                marginBottom={"5px"}

              />
              {errors.email && <Text color="red.500" fontSize="sm">{errors.email}</Text>}
            </FormControl>

            <FormControl isInvalid={!!errors.password}>
              <FormLabel ms="4px" fontSize="sm" fontWeight="500" color={textColor} display="flex" marginTop={"5px"}>
                Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired
                  fontSize="sm"
                  placeholder="Password"
                  mb="24px"
                  size="lg"
                  variant="auth"
                  name="password"
                  value={formState.password}
                  onChange={handleChange}
                  type={show ? "text" : "password"}
                  marginBottom={"5px"}
                />
                <InputRightElement display='flex' alignItems='center' mt='4px'>
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              {errors.password && <Text color="red.500" fontSize="sm">{errors.password}</Text>}
            </FormControl>
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              onClick={submit}
              mb="24px"
              marginTop={"15px"}
            >
              Login
            </Button>
            <Flex
              flexDirection='column'
              justifyContent='center'
              alignItems='start'
              maxW='100%'
              mt='0px'>
              <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
                Not registered yet?
                <NavLink to='/auth/sign-up'>
                  <Text
                    color={textColorBrand}
                    as='span'
                    ms='5px'
                    fontWeight='500'>
                    Create an Account
                  </Text>
                </NavLink>
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </DefaultAuth>
    </>
  );
}

export default SignIn;
