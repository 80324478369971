import { Box, Button } from '@chakra-ui/react';
import RichText from '../../../components/richTextBox/RichTextBox'
import StudentApiService from '../../../services/StudentApiservice';
import { useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { useMyContext } from '../../../contexts/ContextProvider';
import { useHistory } from 'react-router-dom';
import React from 'react';
import BackButton from '../../../components/backButton/BackButton';


const CaseReport = () => {
  const { GetCasesData, getId, getSensationResult } = useMyContext();
  const history = useHistory();
  const [richTextValue, setRichTextValue] = useState('');
  const handleRichTextChange = (value) => {
    setRichTextValue(value);
  };

  const submit = async () => {
    try {
      const studentApiService = new StudentApiService();
      const data = GetCasesData[0]
      let result: any = await studentApiService.updateCaseStatus({
        id: data?.id,
        case_id: data?.case_id,
        student_id: getId,
        report: richTextValue,
        sensation: getSensationResult,
        voidSensation: "none"
      });
      result = JSON.parse(result)
      toast(result.data);
      setTimeout(() => {
        history.push("get-assigned-cases");
      }, 1500);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  return (
    <>
      <Box pt={{ md: '80px', xl: '80px' }}>
        <Toaster />
        <div>
          <RichText onRichTextChange={handleRichTextChange} />
        </div>
        <div style={{ marginTop: "50px", display: 'flex', flexDirection: "row" }}>
          <BackButton />
          <Button colorScheme="blue" mr={3} size="lg" onClick={submit}>
            Submit
          </Button>
        </div>
      </Box>
    </>
  )
}

export default CaseReport
