import React from 'react';
import { Button } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

const BackButton: React.FC = () => {
  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Button onClick={handleBack} colorScheme="teal" mr={3} size="lg" >
      Back
    </Button>
  );
};

export default BackButton;