import React from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  Select,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "../../../assets/img/auth/auth.png";
import AuthService from "../../../services/AuthService";
import toast, { Toaster } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';

interface FormState {
  name: string;
  email: string;
  password: string;
  confirm_password: string;
  phoneNumber: string;
  role: string;
}

function SignUp() {
  const history = useHistory();
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [formState, setFormState] = React.useState<FormState>({
    name: "",
    email: "",
    password: "",
    confirm_password: "",
    phoneNumber: "",
    role: ""
  });
  const [errors, setErrors] = React.useState<Partial<FormState>>({});


  const validate = (name: string, email: string, password: string, confirm_password:string, phoneNumber: string, role: string) => {
    const tempErrors: Partial<FormState> = {};
    if (!name) tempErrors.name = "Name is required.";
    if (!email) tempErrors.email = "Email is required.";
    else if (!/\S+@\S+\.\S+/.test(email)) tempErrors.email = "Email is invalid.";
    if (!password) tempErrors.password = "Password is required.";
    else if (password.length < 6) tempErrors.password = "Password must be at least 6 characters.";
    if (!confirm_password) tempErrors.confirm_password = "Confirm Password is required.";
    else if (confirm_password.length < 6) tempErrors.confirm_password = "Confirm Password must be at least 6 characters.";
    if (!phoneNumber) tempErrors.phoneNumber = "Phone Number is required.";
    else if (/^\+92\d{10}$/.test(phoneNumber)) tempErrors.phoneNumber = "Phone Number must be 12 or 10 digits without + ";
    if (!role) tempErrors.role = "Role is required.";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormState((prev) => ({ ...prev, [name]: value }));
  };

  const handleClick = async () => {
    const { name, email, password,confirm_password, phoneNumber, role } = formState;
    if (validate(name, email, password,confirm_password, phoneNumber, role)) {
      let registrationResult;
      try {
        const authService = new AuthService();
        if(confirm_password==password){
          registrationResult = await authService.register({
            name: name.toString().toLowerCase(),
            email: email.toString().toLowerCase(),
            password: password,
            phoneNumber: phoneNumber,
            role: role,
          });
          toast(JSON.parse(registrationResult).data);
          history.push('/auth/sign-in');
        }
        else{
          toast('Password and Confirm Password Not Match');
        }
      } catch (ex) {
        toast('Internal Server Error');
      }
    } else {
      toast("Please fill the required field in the form.");
    }
  };
  return (
    <>
      <Toaster />
      <DefaultAuth illustrationBackground={illustration} image={illustration}>
        <Flex
          maxW={{ base: "100%", md: "max-content" }}
          w='100%'
          mx={{ base: "auto", lg: "0px" }}
          me='auto'
          h='100%'
          alignItems='start'
          justifyContent='center'
          mb={{ base: "30px", md: "60px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "40px", md: "14vh" }}
          flexDirection='column'>
          <Box me='auto'>
            <Heading color={textColor} fontSize='36px' mb='10px'>
              Sign Up
            </Heading>
            <Text
              mb='36px'
              ms='4px'
              color={textColorSecondary}
              fontWeight='400'
              fontSize='md'>
              Welcome to Urodynamic
            </Text>
          </Box>
          <Flex
            zIndex='2'
            direction='column'
            w={{ base: "100%", md: "420px" }}
            maxW='100%'
            background='transparent'
            borderRadius='15px'
            mx={{ base: "auto", lg: "unset" }}
            me='auto'
            mb={{ base: "20px", md: "auto" }}>
            <FormControl isInvalid={!!errors.name}>
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px" marginTop={"5px"}>
                Name<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                placeholder="Your Name"
                name="name"
                value={formState.name}
                onChange={handleChange}
                mb="24px"
                fontWeight="500"
                size="lg"
                marginBottom={"5px"}
              />
              {errors.name && <Text color="red.500" fontSize="sm">{errors.name}</Text>}
            </FormControl>

            <FormControl isInvalid={!!errors.email}>
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px" marginTop={"5px"}>
                Email<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                placeholder="Your Email"
                name="email"
                value={formState.email}
                onChange={handleChange}
                mb="24px"
                fontWeight="500"
                size="lg"
                marginBottom={"5px"}
                
              />
              {errors.email && <Text color="red.500" fontSize="sm">{errors.email}</Text>}
            </FormControl>

            <FormControl isInvalid={!!errors.password}>
              <FormLabel ms="4px" fontSize="sm" fontWeight="500" color={textColor} display="flex" marginTop={"5px"}>
                Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired
                  fontSize="sm"
                  placeholder="Password"
                  mb="24px"
                  size="lg"
                  type="password"
                  variant="auth"
                  name="password"
                  value={formState.password}
                  onChange={handleChange}
                  marginBottom={"5px"}
                />
              </InputGroup>
              {errors.password && <Text color="red.500" fontSize="sm">{errors.password}</Text>}
            </FormControl>
            <FormControl isInvalid={!!errors.confirm_password}>
              <FormLabel ms="4px" fontSize="sm" fontWeight="500" color={textColor} display="flex" marginTop={"5px"}>
                Confirm Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  isRequired
                  fontSize="sm"
                  placeholder="Confirm Password"
                  mb="24px"
                  size="lg"
                  type="password"
                  variant="auth"
                  name="confirm_password"
                  value={formState.confirm_password}
                  onChange={handleChange}
                  marginBottom={"5px"}
                />
              </InputGroup>
              {errors.confirm_password && <Text color="red.500" fontSize="sm">{errors.confirm_password}</Text>}
            </FormControl>

            <FormControl isInvalid={!!errors.phoneNumber}>
              <FormLabel ms="4px" fontSize="sm" fontWeight="500" color={textColor} display="flex" marginTop={"5px"}>
                Phone Number<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                isRequired
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="number"
                placeholder="03xxxxxxxxx"
                name="phoneNumber"
                value={formState.phoneNumber}
                onChange={handleChange}
                mb="24px"
                fontWeight="500"
                size="lg"
                marginBottom={"5px"}
              />
              {errors.phoneNumber && <Text color="red.500" fontSize="sm">{errors.phoneNumber}</Text>}
            </FormControl>

            <FormControl isInvalid={!!errors.role}>
              <FormLabel ms="4px" fontSize="sm" fontWeight="500" color={textColor} display="flex" marginTop={"5px"}>
                Select Role<Text color={brandStars}>*</Text>
              </FormLabel>
              <Select
                variant="auth"
                fontSize="sm"
                name="role"
                value={formState.role}
                onChange={handleChange}
                mb="24px"
                fontWeight="500"
                size="lg"
                marginBottom={"5px"}
              >
                <option value="" disabled>Select Role</option>
                <option value="1">Instructor</option>
                <option value="2">Student</option>
              </Select>
              {errors.role && <Text color="red.500" fontSize="sm">{errors.role}</Text>}
            </FormControl>
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              onClick={handleClick}
              mb="24px"
              marginTop={"15px"}
            >
              Sign Up
            </Button>
            <Flex
              flexDirection='column'
              justifyContent='center'
              alignItems='start'
              maxW='100%'
              mt='0px'>
              <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
                if you are already sign-up
                <NavLink to='/auth/sign-in'>
                  <Text
                    color={textColorBrand}
                    as='span'
                    ms='5px'
                    fontWeight='500'>
                    SignIn
                  </Text>
                </NavLink>
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </DefaultAuth>
    </>
  );
}

export default SignUp;
