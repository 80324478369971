import { Box } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import InstructorApiService from '../../../services/InstructorApiservices';
import AssignedCasesTable from '../default/components/AssignedCasesTable';
import { useMyContext } from '../../../contexts/ContextProvider';
import React from 'react';



export default function AllReports() {
 // eslint-disable-next-line react-hooks/rules-of-hooks
 const [assignedCasesData, setassignedCasesData] = useState([]);
 // eslint-disable-next-line react-hooks/rules-of-hooks
 const { getId, setId, setName } = useMyContext();
 // eslint-disable-next-line react-hooks/rules-of-hooks
 const [isLoading, setIsLoading] = useState(true);
 
 // eslint-disable-next-line react-hooks/rules-of-hooks
 const fetchDataTable = useCallback(async (userId: any) => {
  try {
   const instructorApiService = new InstructorApiService();
   console.log("",userId); 
   let result: any = await instructorApiService.getAllAssignedRecordsReports(userId); 
   result = JSON.parse(result)
   console.log(result);
   setassignedCasesData(result?.['data'] || []);
   setIsLoading(false);
  } catch (error) {
   console.error('Error fetching data:', error);
   setIsLoading(false);
  }
 }, []);
 useEffect(() => {
  const storedLoginDataString = sessionStorage.getItem('loginData');
  const storedLoginData = storedLoginDataString ? JSON.parse(storedLoginDataString) : null;
  if (storedLoginData) {
   setName(storedLoginData?.name)
   setId(storedLoginData?.id);
   fetchDataTable(getId);
  } else {
   console.error('No login data found in sessionStorage');
  }
 }, [fetchDataTable, getId, setId, setName])
 // Chakra Color Mode
 // const brandColor = useColorModeValue('brand.500', 'white');
 // const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');


 return (
  <>
    <Box pt={{ base: '130px', md: '250px', xl: '80px' }}>
     {isLoading ? <p>Loadings...</p> : <AssignedCasesTable tableData={assignedCasesData} page={2} conditionAllreport={true}/>}
    </Box>
  </>
 )
}