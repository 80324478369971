import React, { ReactNode } from 'react';
import { createContext, useContext } from 'react';

interface CasesDataItem {
  id: number;
  student_name: string;
  case_id: number;
  case_name: string;
  remarks: string;
  status: string;
}

interface tableRowObject {
  id: number;
  case_id: number;
  case_name: string;
  student_name: string;
  report: string;
  status: string;
  assignedDate: string;
  submissionDate: string;
  sensation_result: object;
  void_sensation_result: object;
}
type TableRow = tableRowObject

type CasesData = CasesDataItem[];

interface ProviderProps {
  children: ReactNode;
}
interface MyContextProps {
  GetCasesData: CasesData;
  SetCasesData: React.Dispatch<React.SetStateAction<CasesData>>;
  getRoleId: number;
  setRoleId: React.Dispatch<React.SetStateAction<number>>;
  getReportCondition: boolean;
  setReportCondition: React.Dispatch<React.SetStateAction<boolean>>;
  getFormFillBoolean: boolean;
  setFormFillBoolean: React.Dispatch<React.SetStateAction<boolean>>;
  getFormVoidBoolean: boolean;
  setFormVoidBoolean: React.Dispatch<React.SetStateAction<boolean>>;
  getName: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  getId: number;
  setId: React.Dispatch<React.SetStateAction<number>>;
  getTableRow: TableRow;
  setTableRow: React.Dispatch<React.SetStateAction<TableRow>>;
  getSensationResult: [];
  setSensationResult: React.Dispatch<React.SetStateAction<[]>>;
  getVoidSensationResult: [];
  setVoidSensationResult: React.Dispatch<React.SetStateAction<[]>>;
}

const MyContext = createContext<MyContextProps | undefined>(undefined);

export const ContextProvider: React.FC<ProviderProps> = ({ children }) => {
  const [getId, setId] = React.useState<number>();
  const [getRoleId, setRoleId] = React.useState<number>();
  const [getName, setName] = React.useState<string>();
  const [GetCasesData, SetCasesData] = React.useState<CasesData | null>(null);
  const [getTableRow, setTableRow] = React.useState<TableRow | null>(null);
  const [getSensationResult, setSensationResult] = React.useState<[]>(null);
  const [getVoidSensationResult, setVoidSensationResult] = React.useState<[]>(null);
  const [getReportCondition, setReportCondition] = React.useState<boolean>(false);
  const [getFormFillBoolean, setFormFillBoolean] = React.useState<boolean>(false);
  const [getFormVoidBoolean, setFormVoidBoolean] = React.useState<boolean>(false);
  return (
    <MyContext.Provider value={{
      getId, setId, getName, setName, getRoleId, setRoleId, GetCasesData, SetCasesData, getTableRow, setTableRow, getSensationResult
      , setSensationResult, getVoidSensationResult, setVoidSensationResult, getReportCondition, setReportCondition, getFormFillBoolean, setFormFillBoolean,
      getFormVoidBoolean, setFormVoidBoolean
    }}>
      {children}
    </MyContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useMyContext = () => {
  const context = useContext(MyContext);

  if (!context) {
    throw new Error('useMyContext must be used within a MyContextProvider');
  }

  return context;
};
