import Upload from './components/Upload'
import { Grid } from '@chakra-ui/react';
import React from 'react';

export default function Uploader() {
   return (
      <>
         <div style={{display:"flex", flexDirection:"column", justifyContent:"center",justifyItems:"center"}}>
            <Grid>
               <Upload
                  pe='20px'
                  pb={{ base: '100px', lg: '20px' }}
               />
            </Grid>
         </div>
      </>
   )
}
