import { Button, Flex, useColorModeValue, } from '@chakra-ui/react';
import React from 'react';
import { useDropzone } from 'react-dropzone';

function Dropzone(props: { content: JSX.Element | string; onFilesAccepted: (files: File[]) => void; [x: string]: any }) {
  const { content, onFilesAccepted, ...rest } = props;
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      // Call the parent component's callback with the accepted files
      onFilesAccepted(acceptedFiles);
    },
  });
  const bg = useColorModeValue('gray.100', 'navy.700');
  const borderColor = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');

  return (
    <Flex
      align='center'
      justify='center'
      bg={bg}
      border='1px dashed'
      borderColor={borderColor}
      borderRadius='16px'
      w='100%'
      h='max-content'
      minH='100%'
      cursor='pointer'
      {...getRootProps({ className: 'dropzone' })}
      {...rest}
    >
      <input {...getInputProps()} />
      <Button variant='no-effects'>{content}</Button>
    </Flex>
  );
}

export default Dropzone;
