import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

interface ProtectedRouteProps extends RouteProps {
 // eslint-disable-next-line @typescript-eslint/no-explicit-any
 component: React.ComponentType<any>;
 isAuthenticated: boolean;
 allowedRoles: number[];
 redirectPath: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
 component: Component,
 isAuthenticated,
 allowedRoles,
 redirectPath,
 ...rest
}) => {
 const userRole = JSON.parse(sessionStorage.getItem('loginData'))?.role;
 const isAuthorized = isAuthenticated && allowedRoles.includes(userRole);

 return (
  <Route
   {...rest}
   render={(props) =>
    isAuthorized ? (
     <Component {...props} />
    ) : (
     <Redirect to={redirectPath} />
    )
   }
  />
 );
};

export default ProtectedRoute;
