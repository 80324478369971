import React, { useState, useEffect, useCallback } from 'react';
import StudentApiService from '../../../services/StudentApiservice';
import { Box, Heading, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import { ButtonCom } from './Button';
import './CSS/DynamicTable.css';

interface Data {
  [key: string]: any;
}

interface Props {
  fileName?: string;
  tableName?: string;
  buttonBoolean: boolean;
  dataValues?: Data[];
}

const DynamicTable: React.FC<Props> = ({ tableName, buttonBoolean = false, fileName, dataValues }) => {
  const [data, setData] = useState<Data[]>([]);
  const [headers, setHeaders] = useState<string[]>([]);
  const [getFillingResult, setFillingResult] = useState(false);
  const [getFillingResultButton, setFillingResultButton] = useState({
    className: "button-gap",
    label: "Waiting Result...",
    disable: true,
  });

  const fetchData = useCallback(async () => {
    const studentApiService = new StudentApiService();
    const abortController = new AbortController();
    
    try {
      const response = await studentApiService.getCaseData(fileName.replace('upload/', ""), { signal: abortController.signal });
      const data = JSON.parse(response);
      console.log(response,"response");
      
      setData(data.data);
      if (data.data.length > 0) {
        const keys = Object.keys(data.data[0]);
        setHeaders(keys);
      }
    } catch (error) {
      console.log(error);

    }

    return () => {
      abortController.abort();
    };
  }, [fileName]);

  useEffect(() => {
    if (!buttonBoolean) {
      fetchData();
    } else {
      setData(dataValues || []);
      if (dataValues?.length > 0) {
        const keys = Object.keys(dataValues[0]);
        setHeaders(keys);
      }
    }
  }, [buttonBoolean, dataValues, fileName, fetchData]);

  const handleSet = () => {
    setFillingResult(true);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setFillingResultButton({
        className: "button-focus",
        label: `Show ${tableName}`,
        disable: false,
      });
    }, 10000);

    return () => clearTimeout(timeoutId);
  }, [tableName]);

  return (
    <>
      {!buttonBoolean ? (
        <>
          <ButtonCom
            className={getFillingResultButton.className}
            handleClick={handleSet}
            label={getFillingResultButton.label}
            disabled={getFillingResultButton.disable}
          />
          {getFillingResult && (
            <>
              <Heading as="h5" size="md" mt={4} textAlign="center">
                {tableName}
              </Heading>
              <Box className="responsive-table">
                <Table variant="striped" style={{ width:"100%"}}>
                  <Thead>
                    <Tr>
                      {headers.map(header => (
                        <Th key={header}>{header}</Th>
                      ))}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data.map((item, index) => (
                      <Tr key={index}>
                        {headers.map(header => (
                          <Td key={header}>{item[header]}</Td>
                        ))}
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </>
          )}
        </>
      ) : (
        <>
          <Heading
            as="h1"
            size="lg"
            mt={4}
            textAlign="center"
          >
            {tableName}
          </Heading>
          <Box display={"flex"} mt={4}>
            <Table variant="striped">
              <Thead>
                <Tr>
                  {headers?.map(header => (
                    <Th key={header}>{header}</Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {
                  data?.map((item, index) => (
                    <Tr key={index}>
                      {headers.map(header => (
                        <Td key={header}>{item[header]}</Td>
                      ))}
                    </Tr>
                  ))}
              </Tbody>
            </Table>
          </Box>
        </>
      )}
    </>
  );
};

export default DynamicTable;
