import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/App.css';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import AdminLayout from './layouts/admin';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme';
import SignIn from './views/auth/signIn';
import SignUp from './views/auth/signUp';
import ProtectedRoute from './views/auth/ProtectedRoute';
import AuthService from './services/AuthService';
import { ContextProvider } from './contexts/ContextProvider';
const authService = new AuthService();
const isAuthenticated = authService.isAuthenticated();

ReactDOM.render(
	<ChakraProvider theme={theme}>
		<React.StrictMode>
				<HashRouter>
					<Switch>
						<Route path="/auth/sign-in" component={SignIn} />
						<Route path="/auth/sign-up" component={SignUp} />
						<ContextProvider>
							<ProtectedRoute
								path="/admin"
								component={AdminLayout}
								isAuthenticated={isAuthenticated}
								allowedRoles={[1, 2]}
								redirectPath="/auth/sign-in"
							/>
						</ContextProvider>
						<Redirect from="/" to="/auth/sign-in" />
					</Switch>
				</HashRouter>
		</React.StrictMode>
	</ChakraProvider>,
	document.getElementById('root')
);
