class InstructorApiService {
  baseUrl = process.env.REACT_APP_API_URL;
  // INSTRUCTOR API
  async uploadCsvFile(file: File, userId): Promise<string> {
    const url = `${this.baseUrl}/api/convert?id=${userId}`;

    try {
      const formData = new FormData();
      formData.append('csvFile', file, file.name);
      const response = await fetch(url, {
        method: 'POST',
        body: formData,
        redirect: 'follow',
      });

      if (!response.ok) {
        throw new Error(`Upload failed with status ${response.status}`);
      }

      const result = await response.text();
      return result;
    } catch (error) {
      console.error('Error during API call:', error);
      throw error;
    }
  }
  async getAllCases(userId): Promise<string> {
    const url = `${this.baseUrl}/api/get-all-cases?id=${userId}`;
    return this.fetchData(url);
  }

  async getAllCounts(userId): Promise<string> {
    const url = `${this.baseUrl}/api/get-all-counts?id=${userId}`;
    return this.fetchData(url);
  }

  async getAllAssignedRecords(userId): Promise<string> {
    const url = `${this.baseUrl}/api/get-all-assigned-records?id=${userId}`;
    return this.fetchData(url);
  }
  async getAllAssignedRecordsReports(userId): Promise<string> {
    const url = `${this.baseUrl}/api/get-all-assigned-records-reports?id=${userId}`;
    return this.fetchData(url);
  }

  async getAllStudents(): Promise<string> {
    const url = `${this.baseUrl}/api/all-student`;
    return this.fetchData(url);
  }

  async AssignedCase(instructorId: number, caseId: number, studentIds: number[]): Promise<string> {
    const url = `${this.baseUrl}/api/assigned-case?id=${instructorId}`;
    try {
      const body = JSON.stringify({
        "studentIds": studentIds,
        "caseId": caseId
      });
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: body,
      });
      if (!response.ok) {
        throw new Error(`Upload failed with status ${response.status}`);
      }
      const result = await response.json();
      return result;
    } catch (error) {
      console.error('Error during API call:', error);
      throw error;
    }
  }

  // async updateCaseRemarks(caseData: {
  //   id: number;
  //   case_id: number;
  //   instructor_id: number;
  //   remarks: string;
  //   status: number;
  // }): Promise<string> {
  //   const url = `${this.baseUrl}/api/update-case-remarks`;

  //   try {
  //     const response = await fetch(url, {
  //       method: 'PUT',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(caseData),
  //       redirect: 'follow',
  //     });

  //     if (!response.ok) {
  //       throw new Error(`API call failed with status ${response.status}`);
  //     }

  //     const result = await response.text();
  //     return result;
  //   } catch (error) {
  //     console.error('Error during API call:', error);
  //     throw error;
  //   }
  // }
  async updateCaseStatus(caseData: {
    id: number;
    case_id: number;
    instructor_id: number;
    status: number;
    remarks: string;
  }): Promise<string> {
    const url = `${this.baseUrl}/api/update-case-status`;

    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(caseData),
        redirect: 'follow',
      });

      if (!response.ok) {
        throw new Error(`API call failed with status ${response.status}`);
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error('Error during API call:', error);
      throw error;
    }
  }

  private async fetchData(url: string): Promise<string> {
    try {
      const response = await fetch(url, {
        method: 'GET',
        redirect: 'follow',
      });

      if (!response.ok) {
        throw new Error(`API call failed with status ${response.status}`);
      }

      const result = await response.text();
      return result;
    } catch (error) {
      console.error('Error during API call:', error);
      throw error;
    }
  }

}

export default InstructorApiService;


