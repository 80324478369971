import React from 'react';

interface Buttontypes {
 handleClick?: () => void | number | string | boolean,
 label?: string,
 className?: string,
 disabled?: boolean;
 // eslint-disable-next-line @typescript-eslint/no-explicit-any
 inLineStyle?:  React.CSSProperties;
}

export const ButtonCom = ({ handleClick, label, className, disabled = false ,inLineStyle }: Buttontypes) => {
 return (
  <button style={inLineStyle} className={`button ${className}`} onClick={handleClick} disabled={disabled}>{label}</button>
 )
}


