// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.responsive-table {
  width: 100%;
  overflow-x: auto;
}

.responsive-table table {
  width: 100%;
  border-collapse: collapse;
}

.responsive-table th,
.responsive-table td {
  padding: 8px 16px;
  /* border: 1px solid #ddd; */
  text-align: left;
}

@media (max-width: 600px) {

  .responsive-table th,
  .responsive-table td {
    padding: 4px 8px;
  }
}

/* Optional: improve table appearance on smaller screens */
@media (max-width: 768px) {

  .responsive-table th,
  .responsive-table td {
    padding: 6px 12px;
  }
}

@media (min-width: 769px) {

  .responsive-table th,
  .responsive-table td {
    padding: 8px 16px;
  }
}

@keyframes glowing_button_focus {
  0% {
    background-color: #55e0d2;
    box-shadow: 0 0 3px #4FD1C5;
  }

  50% {
    background-color: #4FD1C5;
    box-shadow: 0 0 40px #2dc0b1;
  }

  100% {
    background-color: #2dc0b1;
    box-shadow: 0 0 3px #2dc0b1;
  }
}


.button-focus {
  font-size: 15px;
  font-weight: bold;
  margin: 21px;
  padding: 20px;
  background: var(--teal-teal-300, #4FD1C5);
  color: black;
  animation: glowing_button_focus 1000ms infinite;
}`, "",{"version":3,"sources":["webpack://./src/views/student/reports/CSS/DynamicTable.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;;EAEE,iBAAiB;EACjB,4BAA4B;EAC5B,gBAAgB;AAClB;;AAEA;;EAEE;;IAEE,gBAAgB;EAClB;AACF;;AAEA,0DAA0D;AAC1D;;EAEE;;IAEE,iBAAiB;EACnB;AACF;;AAEA;;EAEE;;IAEE,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,yBAAyB;IACzB,2BAA2B;EAC7B;;EAEA;IACE,yBAAyB;IACzB,4BAA4B;EAC9B;;EAEA;IACE,yBAAyB;IACzB,2BAA2B;EAC7B;AACF;;;AAGA;EACE,eAAe;EACf,iBAAiB;EACjB,YAAY;EACZ,aAAa;EACb,yCAAyC;EACzC,YAAY;EAIZ,+CAA+C;AACjD","sourcesContent":[".responsive-table {\n  width: 100%;\n  overflow-x: auto;\n}\n\n.responsive-table table {\n  width: 100%;\n  border-collapse: collapse;\n}\n\n.responsive-table th,\n.responsive-table td {\n  padding: 8px 16px;\n  /* border: 1px solid #ddd; */\n  text-align: left;\n}\n\n@media (max-width: 600px) {\n\n  .responsive-table th,\n  .responsive-table td {\n    padding: 4px 8px;\n  }\n}\n\n/* Optional: improve table appearance on smaller screens */\n@media (max-width: 768px) {\n\n  .responsive-table th,\n  .responsive-table td {\n    padding: 6px 12px;\n  }\n}\n\n@media (min-width: 769px) {\n\n  .responsive-table th,\n  .responsive-table td {\n    padding: 8px 16px;\n  }\n}\n\n@keyframes glowing_button_focus {\n  0% {\n    background-color: #55e0d2;\n    box-shadow: 0 0 3px #4FD1C5;\n  }\n\n  50% {\n    background-color: #4FD1C5;\n    box-shadow: 0 0 40px #2dc0b1;\n  }\n\n  100% {\n    background-color: #2dc0b1;\n    box-shadow: 0 0 3px #2dc0b1;\n  }\n}\n\n\n.button-focus {\n  font-size: 15px;\n  font-weight: bold;\n  margin: 21px;\n  padding: 20px;\n  background: var(--teal-teal-300, #4FD1C5);\n  color: black;\n  -webkit-animation: glowing_button_focus 1000ms infinite;\n  -moz-animation: glowing_button_focus 1000ms infinite;\n  -o-animation: glowing_button_focus 1000ms infinite;\n  animation: glowing_button_focus 1000ms infinite;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
