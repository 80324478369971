import { Icon } from '@chakra-ui/react';
import { MdDashboard, MdLoop } from 'react-icons/md';

// Admin Imports
import StudentAssignedCasesTable from './getAssignedCases';
import studentRandomCaseTable from './studentRandomCase';
import Case from './reports';
import WriteReport from './case-report';
import React from 'react';

const routes = [
	{
		name: 'Reporting',
		layout: '/admin',
		path: '/reporting',
		icon: <Icon as={MdDashboard} width='25px' height='25px' color='inherit' />,
		role_id:2,
		component: Case
	},
	{
		name: 'WriteReport',
		layout: '/admin',
		path: '/new-report',
		icon: <Icon as={MdLoop} width='25px' height='25px' color='inherit' />,
		role_id:2,
		component: WriteReport
	},
	{
		name: 'Main Dashboard',
		layout: '/admin',
		path: '/get-assigned-cases',
		icon: <Icon as={MdDashboard} width='25px' height='25px' color='inherit' />,
		role_id:2,
		component: StudentAssignedCasesTable
	},
	{
		name: 'Random Case Generation',
		layout: '/admin',
		path: '/random-case-generation',
		icon: <Icon as={MdLoop} width='25px' height='25px' color='inherit' />,
		role_id:2,
		component: studentRandomCaseTable
	},


];
 export default routes
