class AuthService {
  baseUrl = process.env.REACT_APP_API_URL;

  async login(email: string, password: string): Promise<string> {
    const url = `${this.baseUrl}/api/login`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password,
        }),
        redirect: 'follow',
      });

      if (!response.ok) {
        throw new Error(`Login failed with status ${response.status}`);
      }

      const result = await response.text();
      return result;
    } catch (error) {
      console.error('Error during login:', error);
      throw error;
    }
  }

  isAuthenticated(): boolean {
    const loginData = JSON.parse(sessionStorage.getItem('loginData'))?.role;
    
    return loginData!== null || undefined ? true : false
  }

  async register(userDetails: {
    name: string;
    email: string;
    password: string;
    phoneNumber: string;
    role: string;
  }): Promise<string> {
    const url = `${this.baseUrl}/api/registration`;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userDetails),
        redirect: 'follow',
      });

      if (!response.ok) {
        throw new Error(`Registration failed with status ${response.status}`);
      }

      const result = await response.text();

      return result;
    } catch (error) {
      console.error('Error during registration:', error);
      throw error;
    }
  }
}

export default AuthService;