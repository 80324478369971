import { useCallback, useEffect, useState } from 'react';
import { Box, Button } from '@chakra-ui/react';
import StudentApiService from '../../../services/StudentApiservice';
import { useMyContext } from '../../../contexts/ContextProvider';
import React from 'react';
import StudentRandomAssignedCasesTable from './components/StudentRandomAssignedCasesTable';
import toast, { Toaster } from 'react-hot-toast';

const GenerateRandomCase = () => {
  const [allCasesData, setAllCasesData] = useState([]);
  const { getId, setId } = useMyContext();

  const fetchDataRandom = async () => {
    try {
      const storedLoginDataString = sessionStorage.getItem('loginData');
      const storedLoginData = storedLoginDataString ? JSON.parse(storedLoginDataString) : null;
      if (storedLoginData) {
        setId(storedLoginData.id);
        const studentApiService = new StudentApiService();
        const result: any = await studentApiService.getRandomAssignedCases(getId);
        console.log(result);
        toast(result.data);
      } else {
        console.error('No login data found in sessionStorage');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const fetchData = useCallback(async () => {
    try {
      const storedLoginDataString = sessionStorage.getItem('loginData');
      const storedLoginData = storedLoginDataString ? JSON.parse(storedLoginDataString) : null;
      if (storedLoginData) {
        setId(storedLoginData.id);
        const studentApiService = new StudentApiService();
        const result: any = await studentApiService.getRandomAssignedCasesDataTable(getId);
        setAllCasesData(result?.data || []);
      } else {
        console.error('No login data found in sessionStorage');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [getId, setId]);

  useEffect(() => {
    fetchData();
  }, [fetchData, getId, setId]);

  return (
    <Box pt={{ base: '130px', md: '90px', xl: '90px' }}>
      <Toaster />
      <Button colorScheme="blue"
        onClick={() => fetchDataRandom()} >
        Random Case Generate
      </Button>
      <br />
      {allCasesData.length > 0 ? (
        <StudentRandomAssignedCasesTable tableData={allCasesData} />
      ) : (
        <h4 style={{marginTop:"20px"}}>
          No Case Found Kindly Generate New Case
        </h4>
      )}
    </Box>
  );
};

export default GenerateRandomCase;
