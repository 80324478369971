import React from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';
interface PaginationProps {
  canPreviousPage: boolean;
  canNextPage: boolean;
  pageIndex: number;
  pageCount: number;
  gotoPage?: (page: number) => void;
  previousPage: () => void;
  nextPage: () => void;
}

const Pagination: React.FC<PaginationProps> = ({
  canPreviousPage,
  canNextPage,
  pageIndex,
  pageCount,
  previousPage,
  nextPage,
}) => (
  <Flex justifyContent="space-between" alignItems="center" mt="4" margin='12px'>
    <Button colorScheme="blue" variant="solid"  onClick={() => previousPage()} disabled={!canPreviousPage}>
      Previous
    </Button>
    <Text justifyContent="center" mt="4">
      Page <strong>{pageIndex + 1}</strong> of <strong>{pageCount}</strong>
    </Text>
    <Button colorScheme="blue" variant="solid" onClick={() => nextPage()} disabled={!canNextPage}>
      Next
    </Button>
  </Flex>
);

export default Pagination;
