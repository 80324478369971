class StudentApiService {
  baseUrl = process.env.REACT_APP_API_URL;

  async getCaseData(fileName: string, options?: RequestInit): Promise<string> {
    const url = `${this.baseUrl}/api/student/get-json/${fileName}`;
    return this.fetchData(url, options);
  }

  async updateCaseStatus(caseData: {
    id: number;
    case_id: number;
    student_id: number;
    report: string;
    sensation: any;
    voidSensation: any;
  }): Promise<string> {
    const url = `${this.baseUrl}/api/student/update-case-status`;

    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(caseData),
      });

      if (!response.ok) {
        throw new Error(`API call failed with status ${response.status}`);
      }

      const result = await response.text();
      return result;
    } catch (error) {
      console.error('Error during API call:', error);
      throw error;
    }
  }

  async getAssignedCases(studentId: number, options?: RequestInit): Promise<string> {
    const url = `${this.baseUrl}/api/student/assigned-cases?student_id=${studentId}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        ...options,
        headers: {
          'Content-Type': 'application/json',
          ...options?.headers // Include any additional headers passed in
        },
      });

      if (!response.ok) {
        throw new Error(`API call failed with status ${response.status}`);
      }

      const result = await response.text();
      return result;
    } catch (error) {
      console.error('Error during API call:', error);
      throw error;
    }
  }

  async getRandomAssignedCasesDataTable(studentId: number): Promise<string> {
    const url = `${this.baseUrl}/api/student/random-assigned-cases?student_id=${studentId}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        redirect: 'follow',
      });

      if (!response.ok) {
        throw new Error(`API call failed with status ${response.status}`);
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error('Error during API call:', error);
      throw error;
    }
  }

  async getRandomAssignedCases(studentId: number): Promise<string> {
    const url = `${this.baseUrl}/api/student/random-case?id=${studentId}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        redirect: 'follow',
      });
      if (!response.ok) {
        throw new Error(`API call failed with status ${response.status}`);
      }
      const result = await response.json();
      return result;
    } catch (error) {
      console.error('Error during API call:', error);
      throw error;
    }
  }
  private async fetchData(url: string, options?: RequestInit): Promise<string> {
    try {
      const response = await fetch(url, {
        ...options, // Include any additional options passed in
        headers: {
          'Content-Type': 'application/json',
          ...options?.headers // Include any additional headers passed in
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.text();
    } catch (error) {
      console.error('Error during API call:', error);
      throw error;
    }
  }
}
export default StudentApiService;
