import { MdAssignment, MdHome, MdHistoryEdu, } from 'react-icons/md';
import { Icon } from '@chakra-ui/react';
import AllCases from './allCases';
import MainDashboard from './default';
import AllReports from './all-reports';
import InstructorCaseWriteReport from './instructorCaseWriteReport';
import React from 'react';
// import Uploader from 'views/admin/uploader';

 const routesInstructure = [
	{
		name: 'Dashboard',
		layout: '/admin',
		path: '/default',
		icon: <Icon as={MdHome} width='25px' height='25px' color='inherit' />,
		role_id:1,
		component: MainDashboard
	},
	{
		name: 'All-Cases',
		layout: '/admin',
		icon: <Icon as={MdAssignment} width='25px' height='25px' color='inherit' />,
		path: '/all-cases',
		role_id:1,
		component: AllCases
	},

	{
		name: 'All-Reports',
		layout: '/admin',
		path: '/all-reports',
		icon: <Icon as={MdHistoryEdu} width='25px' height='25px' color='inherit' />,
		role_id:1,
		component: AllReports
	},
	{
		name: 'Write-Marks',
		layout: '/admin',
		path: '/remarks-report',
		icon: <Icon as={MdHistoryEdu} width='25px' height='25px' color='inherit' />,
		role_id:1,
		component: InstructorCaseWriteReport
	},
		// {
	// 	name: 'Upload-Cases',
	// 	layout: '/admin',
	// 	path: '/uploader',
	// 	icon: <Icon as={MdLock} width='25px' height='25px' color='inherit' />,
	// 	role_id:1,
	// 	component: Uploader
	// },
]
export default routesInstructure
