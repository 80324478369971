import {
  Button, Text,
  Stack,
  RadioGroup,
  Radio
} from '@chakra-ui/react';
import RichText from '../../../components/richTextBox/RichTextBox'
import { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { useMyContext } from '../../../contexts/ContextProvider';
import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';
import InstructorApiService from '../../../services/InstructorApiservices';
import DynamicTable from '../../student/reports/DynamicTable';
import FillingPhase from '../../student/reports/FillingPhase';
import VoidingPhase from '../../student/reports/VoidingPhase';
import StudentApiService from '../../../services/StudentApiservice';
import './style.css';
import React from 'react';
import BackButton from '../../../components/backButton/BackButton';


interface DataItem {
  "Time-Calculated": number;
  "ML": number;
  "ML-Calculated": number;
  "Constant": number;
  "Time-(converted)": number;
  "Pves-1": number;
  "Pabd-2": number;
  "Pdet": number;
  "Qura": number;
  "Vmic": number;
  "Marker": number;
  "Marker-1": string;
  "Marker-2": string;
}
type ApiResponseData = DataItem[];


const InstructorCaseWriteReport = () => {
  const { getId, getTableRow, getReportCondition } = useMyContext();
  const [allData, setAllData] = useState<ApiResponseData>([]);
  const [isError, setIsError] = useState(false);
  const [getVolume, setVolume] = useState(0);
  const history = useHistory();
  const [richTextValue, setRichTextValue] = useState('');
  const [getStatus, setStatus] = useState('');
  const handleRichTextChange = (value) => {
    setRichTextValue(value);
  };
  const sensation = getTableRow?.['sensation_name'];
  const cough = getTableRow?.['cough_name'];
  const voiding1 = getTableRow?.['voiding1_name'];
  const voiding2 = getTableRow?.['voiding2_name'];
  let sensation_result: any = getTableRow?.['sensation_result']
  sensation_result = JSON.parse(sensation_result);
  console.log(sensation_result);

  // let void_sensation_result: any = getTableRow?.['void_sensation_result']
  // void_sensation_result = JSON.parse(void_sensation_result);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        const studentApiService = new StudentApiService();
        const result = await studentApiService.getCaseData(getTableRow?.case_name)
        const data = JSON.parse(result).data;
        if (isMounted) {
          setAllData(data);
        }
      } catch (error) {
        if (isMounted) {
          setIsError(true);
        }
      }
    };

    fetchData();
    setVolume(660);
    return () => {
      isMounted = false;
    };
  }, [getTableRow?.case_name]);


  const submit = async () => {
    try {
      const data = getTableRow;
      const instructorApiService = new InstructorApiService();
      const resultUpdateCaseStatus = await instructorApiService.updateCaseStatus({
        id: data?.id,
        case_id: data?.case_id,
        instructor_id: getId,
        status: parseInt(getStatus),
        remarks: richTextValue,
      });
      if (resultUpdateCaseStatus?.['success']) {
        toast(resultUpdateCaseStatus?.['data']);
        setTimeout(() => {
          history.push("admin/default");
        }, 1500);
      }
      else {
        toast(resultUpdateCaseStatus?.['data']);
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };
  // Debugging
  console.log('All Data:', allData);
  return (
    <div>
      <Toaster />
      <div style={{ marginTop: "70px", justifyContent: "center", alignContent: "center", textAlign: "center" }}>
        <Text fontSize='3xl'>Report</Text>
        <Text fontSize='2xl'>View Student Report</Text>
        <div className='border'>
          {getTableRow?.report !== null && getTableRow?.report !== undefined
            ? parse(String(getTableRow?.report))
            : "NA"}
        </div>
        {isError && <Text color='red.500'>Error fetching data</Text>}
        <FillingPhase
          allData={allData}
          volume={getVolume}
          animationValue={false}
          animationMarker={false}
        />
        <Text fontSize='3xl'>User Filling Sensation Result</Text>
        <DynamicTable tableName='Submitted Filling Sensation Result' buttonBoolean={true} dataValues={sensation_result} />
        <DynamicTable fileName={sensation} tableName='Original Sensation Result' buttonBoolean={false} />
        <DynamicTable fileName={cough} tableName='Cough leak point Results' buttonBoolean={false} />

        <VoidingPhase
          allData={allData}
          ISetVoid={true}
          animationValue={false}
        />
        {/* <Text fontSize='3xl'> User Voiding Sensation Result</Text>
        <DynamicTable buttonBoolean={true} dataValues={void_sensation_result} /> */}
        <DynamicTable fileName={voiding1} tableName='Original Sensation Result-1' buttonBoolean={false} />
        <br />
        <DynamicTable fileName={voiding2} tableName='Original Sensation Result-2' buttonBoolean={false} />
        {!getReportCondition &&
          <>
            <Text fontSize='3xl' align={'center'}>Result Status</Text>
            <div className='border result'>
              <RadioGroup onChange={(value) => setStatus(value)}>
                <Stack spacing={5} direction='row'>
                  <Radio colorScheme='green' value={'3'} size='lg'>
                    Approved
                  </Radio>
                  <Radio colorScheme='red' value={'4'} size='lg'>
                    Reject
                  </Radio>
                </Stack>
              </RadioGroup>
            </div>
            <RichText onRichTextChange={handleRichTextChange} />
          </>
        }
      </div>
      {!getReportCondition &&
        <>
          <div style={{ marginTop: "50px" }}>
            <BackButton />
            <Button colorScheme="blue" mr={3}  size="lg" onClick={submit}>
              Submit
            </Button>
          </div>
        </>
      }
    </div>
  )
}

export default InstructorCaseWriteReport
