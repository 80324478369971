import {
  Box,
  Flex,
  Icon,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { Button } from "@chakra-ui/react";
import { useHistory } from 'react-router-dom';

// Custom components
import Card from "../../../../components/card/Card";
import * as React from "react";
import { MdCancel, MdCheckCircle, MdOutlineError } from "react-icons/md";
import { useMyContext } from "../../../../contexts/ContextProvider";
import ViewReportModal from "../../../instructor/default/components/ViewReportModal";
import Pagination from '../../../instructor/allCases/components/Pagination';



type RowObj = {
  id: number;
  case_id: number;
  case_name: string;
  instructor_name: string;
  student_name: string;
  remarks: string;
  status: string;
  button: unknown;
  assignedDate: string;
  submissionDate: string;
};

const columnHelper = createColumnHelper<RowObj>();

// const columns = columnsDataCheck;
export default function StudentAssignedCasesTable(props: { tableData: any }) {
  const history = useHistory();
  const { SetCasesData } = useMyContext();
  const [isModalViewOpen, setIsModalViewOpen] = React.useState(false);
  const [getContent, setContent] = React.useState("");
  const { tableData } = props;
  const [ setPagination] = React.useState<PaginationState>({ pageIndex: 0, pageSize: 10 });
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const defaultData = tableData;

  const openViewModal = (content) => {
    setContent(content)
    setIsModalViewOpen(true);
  };

  const closeViewModal = () => {
    setIsModalViewOpen(false);
  };

  const navigate = async (rowData: RowObj) => {
    try {
      SetCasesData([rowData])
      history.push("reporting");
    } catch (ex) {
      console.log(ex);
    }
  };

  const columns = [
    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "8px", lg: "10px" }}
          color="gray.400">
          Id
        </Text>
      ),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info?.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("instructor_name", {
      id: "instructor_name",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "8px", lg: "10px" }}
          color="gray.400"
        >
          Instructor-Name
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {info?.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("case_id", {
      id: "case_id",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "8px", lg: "10px" }}
          color="gray.400"
        >
          Case-Id
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info?.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("assignedDate", {
      id: "assignedDate",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "6px",md:"9px", lg: "11px" }}
          color="gray.400"
        >
          Assigned-Date
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info?.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("submissionDate", {
      id: "submissionDate",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "8px", lg: "10px" }}
          color="gray.400"
        >
          Submission-Date
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info?.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("status", {
      id: "status",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "8px", lg: "10px" }}
          color="gray.400"
        >
          Status
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Icon
            w="24px"
            h="24px"
            me="5px"
            color={
              info?.getValue() === "Assigned"
                ? "green.500"
                : info?.getValue() === "In-Progress"
                  ? "red.500"
                  : info?.getValue() === "Rejected"
                    ? "orange.500"
                    : null
            }
            as={
              info?.getValue() === "Assigned"
                ? MdCheckCircle
                : info?.getValue() === "In-Progress"
                  ? MdCancel
                  : info?.getValue() === "Rejected"
                    ? MdOutlineError
                    : null
            }
          />
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info?.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("remarks", {
      id: "remarks",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "8px", lg: "10px" }}
          color="gray.400"
        >
          Remarks
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">

            {info?.row.original.status == 'Approved' ?
              <Button colorScheme="blue"
                onClick={() => openViewModal(info?.getValue())} disabled={false} >
                Check
              </Button>
              : <Button colorScheme="red" disabled={true}>
                Disable
              </Button>
            }
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("button", {
      id: "button",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "8px", lg: "10px" }}
          color="gray.400"
        >
          Start-Reporting
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info?.row.original.status == 'Assigned' ?
              <Button colorScheme="blue"
                onClick={() => navigate(info?.row?.original)} >
                Process
              </Button>
              :
              <Button colorScheme="red" disabled={true}>
              Disable
            </Button>
            }
          </Text>
        </Flex>
      ),
    }),
  ];
  const [data] = React.useState(() => [...defaultData]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onPaginationChange: setPagination,
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });
  return (
    <>
      <Card
        flexDirection="column"
        w="100%"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Assigned Cases
          </Text>
        </Flex>
        <Box>
          <Table variant="simple" color="gray.500" mb="24px" mt="12px">
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        pe="10px"
                        borderColor={borderColor}
                        cursor="pointer"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        <Flex
                          justifyContent="space-between"
                          align="center"
                          fontSize={{ sm: "8px", lg: "10px" }}
                          color="gray.400"
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: "",
                            desc: "",
                          }[header.column.getIsSorted() as string] ?? null}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table
                .getRowModel()
                .rows.slice(0, 11)
                .map((row) => {
                  return (
                    <Tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <Td
                            key={cell.id}
                            fontSize={{ sm: "14px" }}
                            minW={{ sm: "150px", md: "200px", lg: "auto" }}
                            borderColor="transparent"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </Box>
        <Pagination
        canPreviousPage={table.getCanPreviousPage()}
        canNextPage={table.getCanNextPage()}
        pageIndex={table.getState().pagination.pageIndex}
        pageCount={table.getPageCount()}
        gotoPage={table.gotoPage}
        previousPage={table.previousPage}
        nextPage={table.nextPage}
      />
      </Card>
      <ViewReportModal content={getContent} isOpen={isModalViewOpen} onClose={closeViewModal} />
    </>
  );
}
