import { Box, Button, Flex, Icon, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  PaginationState,
  getPaginationRowModel,
} from '@tanstack/react-table';
import { useHistory } from 'react-router-dom';
// Custom components
import Card from '../../../../components/card/Card';
import { MdCheckCircle, MdOutlineError, MdReceipt, MdWork, MdCheck } from 'react-icons/md';
import React from 'react';
import { useMyContext } from '../../../../contexts/ContextProvider';
import Pagination from '../../allCases/components/Pagination';

type RowObj = {
  id: number,
  case_id: number,
  case_name: string,
  student_name: string,
  report: string,
  status: string,
  assignedDate: string;
  submissionDate: string;
  sensation_result: object;
  void_sensation_result: object;
};

const columnHelper = createColumnHelper<RowObj>();

export default function AssignedCasesTable(props: { tableData: any, page: number, conditionAllreport?: boolean }) {
  const { tableData } = props;
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const [pagination, setPagination] = React.useState<PaginationState>({ pageIndex: 0, pageSize: 10 });
  const defaultData = tableData;
  const { setTableRow, setReportCondition } = useMyContext();
  const history = useHistory();

  const writeRemarks = (content) => {
    setTableRow(content)
    setReportCondition(props.conditionAllreport)
    history.push("remarks-report")
  };
  const columns = [
    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'>
          Id
        </Text>
      ),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cell: (info: any) => (
        <Flex align='center'>
          <Text color={textColor} fontSize='sm' fontWeight='700'>
            {info.getValue()}
          </Text>
        </Flex>
      )
    }),
    columnHelper.accessor('student_name', {
      id: 'student_name',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'>
          Student
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize='sm' fontWeight='700'>
          {info.getValue()}
        </Text>
      )
    }),
    columnHelper.accessor('case_name', {
      id: 'case_name',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'>
          Case-Name
        </Text>
      ),
      cell: (info: any) => (
        <Flex align='center'>
          <Text color={textColor} fontSize='sm' fontWeight='700'>
            {info.getValue()}
          </Text>
        </Flex>
      )
    }),
    columnHelper.accessor("assignedDate", {
      id: "assignedDate",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Assigned-Date
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info?.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("submissionDate", {
      id: "submissionDate",
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: "10px", lg: "12px" }}
          color="gray.400"
        >
          Submission-Date
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info?.getValue() != '' || undefined || null ? info?.getValue() : "NA"}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('report', {
      id: 'report',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'>
          Write-Remarks
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {props.page === 1 ?
              (info?.row.original.submissionDate != null && info?.row.original.report !== '' &&
                info?.row.original.status !== 'Approved' && info?.row.original.status !== 'Rejected') ?
                <Button colorScheme="blue" onClick={() => writeRemarks(info?.row.original)} disabled={false}>
                  View
                </Button>
                :
                (info?.row.original.status === 'Approved') ?
                  <Button colorScheme="green" disabled={true}>
                    Checked
                  </Button>
                  :
                  (info?.row.original.status === 'Assigned') ?
                    <Button colorScheme="red" disabled={true}>
                      Assigned
                    </Button> :
                    <Button colorScheme="red" disabled={true}>
                      Disable
                    </Button>
              :
              (info?.row.original.status === 'Approved' || info?.row.original.status === 'Rejected') ?
                <Button colorScheme="blue" onClick={() => writeRemarks(info?.row.original)} disabled={false}>
                  View
                </Button> :
                <Button colorScheme="red" disabled={true}>
                  View
                </Button>
            }
          </Text>
        </Flex>
      )
    }),
    columnHelper.accessor('status', {
      id: 'status',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'>
          Status
        </Text>
      ),
      cell: (info) => (
        <Flex align='center'>
          <Icon
            w='24px'
            h='24px'
            me='5px'
            color={
              info?.getValue() === 'Approved' ? (
                'green.500'
              ) : info.getValue() === 'Assigned' ? (
                'green.500'
              ) : info.getValue() === 'In-Progress' ? (
                'orange.500'
              ) : info.getValue() === 'Rejected' ? (
                'red.500'
              ) : info.getValue() === 'Review' ? (
                'orange.500'
              ) : null
            }
            as={
              info.getValue() === 'Approved' ? (
                MdCheck
              ) : info.getValue() === 'Assigned' ? (
                MdCheckCircle
              )
                : info.getValue() === 'In-Progress' ? (
                  MdWork
                ) : info.getValue() === 'Rejected' ? (
                  MdOutlineError
                ) : info.getValue() === 'Review' ? (
                  MdReceipt
                ) : null
            }
          />
          <Text color={textColor} fontSize='sm' fontWeight='700'>
            {info.getValue()}
          </Text>
        </Flex>
      )
    }),
  ];
  const [data] = React.useState(() => [...defaultData]);
  console.log([data]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: setPagination,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true
  });
  return (
    <>
      <Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
        <Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
          <Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
            Assigned Cases Student
          </Text>
        </Flex>
        <Box>
          <Table variant='simple' color='gray.500' mb='24px' mt="12px">
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        pe='10px'
                        borderColor={borderColor}
                        cursor='pointer'
                        onClick={header.column.getToggleSortingHandler()}>
                        <Flex
                          justifyContent='space-between'
                          align='center'
                          fontSize={{ sm: '10px', lg: '12px' }}
                          color='gray.400'>
                          {flexRender(header.column.columnDef.header, header.getContext())}{{
                            asc: '',
                            desc: '',
                          }[header.column.getIsSorted() as string] ?? null}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.slice(0, 11).map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td
                          key={cell.id}
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          borderColor='transparent'>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
        <Pagination
          canPreviousPage={table.getCanPreviousPage()}
          canNextPage={table.getCanNextPage()}
          pageIndex={table.getState().pagination.pageIndex}
          pageCount={table.getPageCount()}
          previousPage={table.previousPage}
          nextPage={table.nextPage}
        />
      </Card>
    </>
  );
}
