import './CSS/Report.css';
import React, { useEffect, useState, lazy, Suspense } from 'react';
import StudentApiService from '../../../services/StudentApiservice';
import { CircleIndicator } from './CircleIndicator';
import { useMyContext } from '../../../contexts/ContextProvider';

const ButtonSection = lazy(() => import('./ButtonSection'));
const FillingPhase = lazy(() => import('./FillingPhase'));
const VoidingPhase = lazy(() => import('./VoidingPhase'));

interface DataItem {
  "Time-Calculated": number;
  "ML": number;
  "ML-Calculated": number;
  "Constant": number;
  "Time-(converted)": number;
  "Pves-1": number;
  "Pabd-2": number;
  "Pdet": number;
  "Qura": number;
  "Vmic": number;
  "Marker": number;
  "Marker-1": string;
  "Marker-2": string;
}
type ApiResponseData = DataItem[];
const Reports = () => {

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { GetCasesData } = useMyContext();
  const [allData, setAllData] = useState<ApiResponseData>([]);
  const [getFilling, setFilling] = useState(false);
  const [getVolume, setVolume] = useState(0);
  const [getFillingButton, setFillingButton] = useState({
    "className": "button-gap",
    "label": "Waiting Place Catheter",
    "disable": true
  });


  const [getCircleIndicatorState, setCircleIndicatorState] = useState({
    "circleIndicatorNormal": "indicator --offline",
    "circleIndicatorStrong": "indicator --offline",
    "circleIndicatorUncontrollable": "indicator --offline",
    "circleIndicatorCough": "indicator --offline",
  });
  const [getIndicatorState, setIndicatorState] = useState({
    "labelBlue": "Place Blue Catheter",
    "classNameBlue": "--blue_glowing",
    "disableBlue": false,
    "classNameRed": "--red_glowing",
    "labelRed": "Place Red Catheter",
    "disableRed": false,
  });

  // const [setCough] = useState({
  //   "classNameYellow": "--yellow",
  // });

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const studentApiService = new StudentApiService();
        const result = await studentApiService.getCaseData(GetCasesData[0]?.case_name)
        const data = JSON.parse(result).data;
        setAllData(data);
      } catch (error) {
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    console.log("GetCasesData?.[0]['volume'].toString()", GetCasesData?.[0]['volume'].toString());
    const bladder = JSON.parse(GetCasesData?.[0]['volume'].toString() ?? 0)[0];
    setVolume(bladder?.['Bladder filling'].replace("ml", ""));
  }, [GetCasesData]);


  // FUNCTION
  const buttonClickIndicator = (condition?: number) => {
    if (condition == 1) {
      setIndicatorState({
        "classNameBlue": "--blue",
        "labelBlue": "Placed",
        "disableBlue": true,
        "classNameRed": "--red_glowing",
        "labelRed": "Place Red Catheter",
        "disableRed": false,
      })
    }
    else if (condition == 2) {
      setIndicatorState({
        "classNameBlue": "--blue",
        "labelBlue": "Placed",
        "disableBlue": true,
        "classNameRed": "--red",
        "labelRed": "Placed",
        "disableRed": true,
      })

      setFillingButton({
        "className": "button-gap",
        "label": "Start Filling",
        "disable": false
      })
    }

  }


  const fillingPhaseFn = () => {
    setFilling(true);
    setFillingButton({
      "className": "--red",
      "label": "Filling",
      "disable": true,
    })
  }
  return (
    <div id="main-container">
      <Suspense fallback={<div>Loading...1</div>}>

        <section className='section-1'>
          <ButtonSection
            blueProps={{
              className: getIndicatorState.classNameBlue,
              handleClick: () => buttonClickIndicator(1),
              label: getIndicatorState.labelBlue,
              disabled: getIndicatorState.disableBlue,
            }}
            redProps={{
              className: getIndicatorState.classNameRed,
              handleClick: () => buttonClickIndicator(2),
              label: getIndicatorState.labelRed,
              disabled: getIndicatorState.disableRed,
            }}
            fillingProps={{ className: getFillingButton.className, handleClick: fillingPhaseFn, label: getFillingButton.label, disabled: getFillingButton.disable }}
          />
          <div className='row-2'>
            <div className='indicators'>
              <div className='heading'>Desire Levels</div>
              <div>
                <CircleIndicator Props={{
                  className: getCircleIndicatorState.circleIndicatorStrong,
                  heading: "Normal Desire"
                }} />
                <CircleIndicator Props={{
                  className: getCircleIndicatorState.circleIndicatorUncontrollable,
                  heading: "Strong Desire"
                }} />
                <CircleIndicator Props={{
                  className: getCircleIndicatorState.circleIndicatorNormal,
                  heading: "Uncontrollable"
                }} />
              </div>
            </div>
            <div className='indicators'>
              <div className='heading'>Instructions</div>
              <div>
                <CircleIndicator Props={{
                  className: getCircleIndicatorState.circleIndicatorCough,
                  heading: "Ask to Cough"
                }} />
                {/* <CircleIndicator Props={{
                  className: "indicator --offline",
                  heading: "Ask to Pee"
                }} /> */}
              </div>
            </div>
          </div>
        </section>
      </Suspense>
      <section className='section-2'>
        <>
          {isLoading && <p>Loading...</p>}
          {isError}
          {getFilling && (
            <Suspense fallback={<div>Loading...2</div>}>
              <FillingPhase
                setFilling={setFilling}
                setCircleIndicator={setCircleIndicatorState}
                allData={allData}
                volume={getVolume}
              />
            </Suspense>
          )}
          <Suspense fallback={<div>Loading...3</div>}>
            <VoidingPhase
              setCircleIndicator={setCircleIndicatorState}
              allData={allData}
              ISetVoid={false}
            />
          </Suspense>
        </>
      </section>
    </div>
  )
}
export default Reports;