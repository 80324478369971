// Chakra Imports
import {
	Avatar,
	Button,
	Flex,
	Icon,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Text,
	useColorModeValue,
	useColorMode
} from '@chakra-ui/react';
// Custom Components
import { SidebarResponsive } from '../sidebar/Sidebar';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { IoMdMoon, IoMdSunny } from 'react-icons/io';
import routes from '../../views/student/routes';
import routesInstructure from '../../views/instructor/routesInstructure';
import { useMyContext } from '../../contexts/ContextProvider';
import { useState } from 'react';
import React from 'react';
export default function HeaderLinks(props: { secondary: boolean }) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars

	const [role] = useState(JSON.parse(sessionStorage.getItem("loginData"))?.role)
	const { secondary } = props;
	const history = useHistory();

	const { colorMode, toggleColorMode } = useColorMode();
	// Chakra Color Mode
	const navbarIcon = useColorModeValue('gray.400', 'white');
	const menuBg = useColorModeValue('white', 'navy.800');
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
	const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
	);
	const { setId,getName } = useMyContext();
	
	const handleLogout = () => {
		sessionStorage.clear()
		setId(0);
		history.push('/auth/sign-in')
	}
	return (
		<>
			<Flex
				w={{ sm: '100%', md: 'auto' }}
				alignItems='center'
				flexDirection='row'
				bg={menuBg}
				flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
				p='10px'
				borderRadius='30px'
				boxShadow={shadow}>
				{role == 2 ?
					<SidebarResponsive routes={routes} /> :
					<SidebarResponsive routes={routesInstructure} />
				}

				<Button
					variant='no-hover'
					bg='transparent'
					p='0px'
					minW='unset'
					minH='unset'
					h='18px'
					w='max-content'
					onClick={toggleColorMode}>
					<Icon
						me='10px'
						h='25px'
						w='25px'
						color={navbarIcon}
						as={colorMode === 'light' ? IoMdMoon : IoMdSunny}
					/>
				</Button>
				<Menu>
					<MenuButton width="100px">
						<Avatar
							_hover={{ cursor: 'pointer' }}
							color='white'
							name={getName?.charAt(0).toUpperCase() + getName?.slice(1)}
							bg='#11047A'
							size='sm'
							w='40px'
							h='40px'
						/>
					</MenuButton>
					<MenuList boxShadow={shadow} p='0px' mt='10px' borderRadius='20px' bg={menuBg} border='none'>
						<Flex w='100%' mb='0px'>
							<Text
								ps='20px'
								pt='16px'
								pb='10px'
								w='100%'
								borderBottom='1px solid'
								borderColor={borderColor}
								fontSize='sm'
								fontWeight='700'
								color={textColor}>
								{getName?.charAt(0).toUpperCase() + getName?.slice(1)}
							</Text>
						</Flex>
						<Flex flexDirection='column' p='10px'>
							<MenuItem
								_hover={{ bg: 'none' }}
								_focus={{ bg: 'none' }}
								color='red.400'
								borderRadius='8px'
								px='14px'>
								<Text fontSize='sm'><Button onClick={handleLogout}>Log out</Button></Text>
							</MenuItem>
						</Flex>
					</MenuList>
				</Menu>
			</Flex>
		</>
	);


}

HeaderLinks.propTypes = {
	variant: PropTypes.string,
	fixed: PropTypes.bool,
	secondary: PropTypes.bool,
	onOpen: PropTypes.func
};
