// Chakra imports
import { Portal, Box, useDisclosure } from "@chakra-ui/react";
import Footer from "../../components/footer/FooterAdmin";
// Layout components
import Navbar from "../../components/navbar/NavbarAdmin";
import Sidebar from "../../components/sidebar/Sidebar";
import { useMyContext } from "../../contexts/ContextProvider";
import { SidebarContext } from "../../contexts/SidebarContext";
import React, { useState, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "../../views/student/routes";
import routesInstructure from "../../views/instructor/routesInstructure";

export default function Dashboard(props: { [x: string]: any }) {
  const { getRoleId, setRoleId } = useMyContext();
  const { ...rest } = props;

  useEffect(() => {
    const storedLoginDataString = sessionStorage.getItem('loginData');
    const role = storedLoginDataString ? JSON.parse(storedLoginDataString)?.role : null;
    setRoleId(role)
  }, [setRoleId]);
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  // functions for changing the states from components

  const getActiveRoute = (routes: RoutesType[]): string => {
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "";
  };
  const getActiveNavbar = (routes: RoutesType[]): boolean => {
    const activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getActiveNavbarText = (routes: RoutesType[]): string | boolean => {
    const activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].name;
      }
    }
    return activeNavbar;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getRoutes = (routes: RoutesType[]): any => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any

    return routes.map((route: RoutesType, key: any) => {
      if (route.layout === "/admin") {
        return (
          <Route
            path={route.layout + route.path}
            component={route.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";
  const { onOpen } = useDisclosure();
  return (
    <Box>
      <SidebarContext.Provider
        value={{
          toggleSidebar,
          setToggleSidebar,
        }}
      >
        {getRoleId === 2 ? <Sidebar routes={routes} display="none" {...rest} /> :
          <Sidebar routes={routesInstructure} display="none" {...rest} />}

        <Box
          float="right"
          minHeight="100vh"
          height="100%"
          overflow="auto"
          position="relative"
          maxHeight="100%"
          w={{ base: "100%", xl: "calc( 100% - 290px )" }}
          maxWidth={{ base: "100%", xl: "calc( 100% - 290px )" }}
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          {getRoleId == 2 ?
            <Portal>
              <Box>
                <Navbar
                  onOpen={onOpen}
                  logoText={"Urodynamic"}
                  brandText={getActiveRoute(routes)}
                  secondary={getActiveNavbar(routes)}
                  message={getActiveNavbarText(routes)}
                  fixed={fixed}
                  {...rest}
                />
              </Box>
            </Portal> :
            <Portal>
              <Box>
                <Navbar
                  onOpen={onOpen}
                  logoText={"Urodynamic"}
                  brandText={getActiveRoute(routesInstructure)}
                  secondary={getActiveNavbar(routesInstructure)}
                  message={getActiveNavbarText(routesInstructure)}
                  fixed={fixed}
                  {...rest}
                />
              </Box>
            </Portal>

          }

          <Box
            mx="auto"
            p={{ base: "20px", md: "30px" }}
            pe="20px"
            minH="100vh"
            pt="50px"
          >
            <Switch>
              {getRoleId === 1 ? getRoutes(routesInstructure) : getRoutes(routes)}
              <Redirect
                from="/"
                to={getRoleId === 1 ? "/admin/default" : "/admin/get-assigned-cases"}
              />
            </Switch>
          </Box>
          <Box>
            <Footer />
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
