import React, { useState, useEffect } from 'react';
import {
  Button,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  PaginationState,
} from '@tanstack/react-table';
import Card from '../../../../components/card/Card';
import Pagination from "./Pagination"
import InstructorApiService from '../../../../services/InstructorApiservices';
import { useMyContext } from '../../../../contexts/ContextProvider';
import toast, { Toaster } from 'react-hot-toast';

interface ModalProps {
  tableData: any
  isOpen: boolean;
  onClose: () => void;
}

type RowObj = {
  id: number,
  name: string,
  email: string,
  added_in_db: string
};

const columnHelper = createColumnHelper<RowObj>();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AssignedCaseModal: React.FC<ModalProps> = ({ tableData, isOpen, onClose }) => {
  const [internalIsOpen, setInternalIsOpen] = useState(false);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const [pagination, setPagination] = React.useState<PaginationState>({ pageIndex: 0, pageSize: 10 });
  const { getId } = useMyContext();

  const [selectedRows, setSelectedRows] = useState<{ [key: number]: boolean }>({});
  const fetchData = async () => {
    try {
      const instructorApiService = new InstructorApiService();
      let result: any = await instructorApiService.getAllStudents();
      result = JSON.parse(result)
      setData(result?.['data'])
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setInternalIsOpen(isOpen);
  }, [isOpen]);


  const closeModal = () => {
    setInternalIsOpen(false);
    onClose();
  };
  const columns = [
    columnHelper.display({
      id: 'selection',
      header: () => (
        <Checkbox
          colorScheme="blue"
          isChecked={Object.keys(selectedRows).length === data.length && data.length > 0}
          isIndeterminate={Object.keys(selectedRows).length > 0 && Object.keys(selectedRows).length < data.length}
          onChange={(e) => {
            const isChecked = e.target.checked;
            setSelectedRows(
              data.reduce((acc, row) => {
                acc[row.id] = isChecked;
                return acc;
              }, {} as { [key: number]: boolean })
            );
          }}
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          colorScheme="blue"
          isChecked={!!selectedRows[row.original.id]}
          onChange={(e) => {
            const isChecked = e.target.checked;
            setSelectedRows((current) => ({
              ...current,
              [row.original.id]: isChecked,
            }));
          }}
        />
      ),
    }),
    columnHelper.accessor('id', {
      id: 'id',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'>
          Id
        </Text>
      ),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      cell: (info: any) => (
        <Flex align='center'>
          <Text color={textColor} fontSize='sm' fontWeight='700'>
            {info.getValue()}
          </Text>
        </Flex>
      )
    }),


    columnHelper.accessor('name', {
      id: 'name',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'>
          Student-Name
        </Text>
      ),
      cell: (info: any) => (
        <Flex align='center'>
          <Text color={textColor} fontSize='sm' fontWeight='700'>
            {info.getValue()}
          </Text>
        </Flex>
      )
    }),
    columnHelper.accessor('email', {
      id: 'email',
      header: () => (
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'>
          Email
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize='sm' fontWeight='700'>
          {info.getValue()}
        </Text>
      )
    })
  ];
  const getSelectedRows = async () => {
    try {
      const selectedDataIds =Object.keys(selectedRows).filter(key => selectedRows[key] === true).map(Number);
      // Object.values(selectedRows)
      console.log(selectedDataIds.length);
      if(selectedDataIds.length!==0){
        const result = await AssignedDataApi(getId, tableData?.id, selectedDataIds);
        toast(result?.['data']);
      }else{
        toast('Select Student to Assign case');
      }
    } catch (error) {
      toast("error");
    }
  };
  const AssignedDataApi = (instructorId: number, caseId: number, studentIds: number[]) => {
    const instructorApiService = new InstructorApiService();
    return instructorApiService.AssignedCase(instructorId, caseId, studentIds);
  };

  const [data, setData] = React.useState(() => [...[]]);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });
  return (
    <div>
      <Toaster />
      <Modal isOpen={internalIsOpen} onClose={closeModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>All-Students</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
              <Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
                <Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
                  Select Students
                </Text>
              </Flex>
              <Box>
                <Table variant='simple' color='gray.500' mb='24px' mt="12px">
                  <Thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <Tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                          return (
                            <Th
                              key={header.id}
                              colSpan={header.colSpan}
                              pe='10px'
                              borderColor={borderColor}
                              cursor='pointer'
                              onClick={header.column.getToggleSortingHandler()}>
                              <Flex
                                justifyContent='space-between'
                                align='center'
                                fontSize={{ sm: '10px', lg: '12px' }}
                                color='gray.400'>
                                {flexRender(header.column.columnDef.header, header.getContext())}{{
                                  asc: '',
                                  desc: '',
                                }[header.column.getIsSorted() as string] ?? null}
                              </Flex>
                            </Th>
                          );
                        })}
                      </Tr>
                    ))}
                  </Thead>
                  <Tbody>
                    {table.getRowModel().rows.slice(0, 11).map((row) => {
                      return (
                        <Tr key={row.id}>
                          {row.getVisibleCells().map((cell) => {
                            return (
                              <Td
                                key={cell.id}
                                fontSize={{ sm: '14px' }}
                                minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                                borderColor='transparent'>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </Td>
                            );
                          })}
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </Box>
              <Pagination
                canPreviousPage={table.getCanPreviousPage()}
                canNextPage={table.getCanNextPage()}
                pageIndex={table.getState().pagination.pageIndex}
                pageCount={table.getPageCount()}
                previousPage={table.previousPage}
                nextPage={table.nextPage}
              />
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={closeModal}>
              Close
            </Button>
            <Button colorScheme="blue" variant="solid" onClick={getSelectedRows}>Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default AssignedCaseModal;
