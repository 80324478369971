// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.border {
 height: 100px;
 border: 2px solid white;
 display: flex;
 border-radius: 10px;
 padding: 10px;
}

.result {
 justify-content: center;
 align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/views/instructor/instructorCaseWriteReport/style.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,uBAAuB;CACvB,aAAa;CACb,mBAAmB;CACnB,aAAa;AACd;;AAEA;CACC,uBAAuB;CACvB,mBAAmB;AACpB","sourcesContent":[".border {\n height: 100px;\n border: 2px solid white;\n display: flex;\n border-radius: 10px;\n padding: 10px;\n}\n\n.result {\n justify-content: center;\n align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
