import { useCallback, useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import StudentApiService from '../../../services/StudentApiservice';
import StudentAssignedCasesTable from './components/StudentAssignedCasesTable';
import { useMyContext } from '../../../contexts/ContextProvider';
import React from 'react';

const GetAssignedCases = () => {
  const [allCasesData, setAllCasesData] = useState([]);
  const { getId, setId,setName } = useMyContext();

  const fetchData = useCallback(async () => {
    const abortController = new AbortController();
    try {
      const storedLoginDataString = sessionStorage.getItem('loginData');
      const storedLoginData = storedLoginDataString ? JSON.parse(storedLoginDataString) : null;
      if (storedLoginData) {
        setName(storedLoginData.name);
        setId(storedLoginData.id);
        const studentApiService = new StudentApiService();
        const result = await studentApiService.getAssignedCases(getId, { signal: abortController.signal });
        const parsedResult = JSON.parse(result);
        setAllCasesData(parsedResult?.data || []);
      } else {
        console.error('No login data found in sessionStorage');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [getId, setId, setName]);

  useEffect(() => {
    fetchData();
  }, [fetchData, getId, setId]);

  return (
    <Box pt={{ base: '130px', md: '250px', xl: '80px' }}>
      {allCasesData.length > 0 ? (
        <StudentAssignedCasesTable tableData={allCasesData} />
      ) : (
        <>No Case Assigned...</>
      )}
    </Box>
  );
};

export default GetAssignedCases;
