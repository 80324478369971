import React, { lazy, Suspense } from 'react';
import { useMyContext } from '../../../contexts/ContextProvider';

const DynamicTable = lazy(() => import('./DynamicTable'));
const SensationForm = lazy(() => import('./SensationForm'));
const DynamicChart = lazy(() => import('./DynamicChart'));
const MarkerChart = lazy(() => import('./MarkerChart'));

interface DataItem {
 "Time-Calculated": number;
 "ML": number;
 "ML-Calculated": number;
 "Constant": number;
 "Time-(converted)": number;
 "Pves-1": number;
 "Pabd-2": number;
 "Pdet": number;
 "Qura": number;
 "Vmic": number;
 "Marker": number;
 "Marker-1": string;
 "Marker-2": string;
}
type ApiResponseData = DataItem[];
interface FillingPhaseProps {
 allData: ApiResponseData;
 setFilling?: React.Dispatch<React.SetStateAction<boolean>>;
 // eslint-disable-next-line @typescript-eslint/no-explicit-any
 setCough?: React.Dispatch<React.SetStateAction<any>>;
 // eslint-disable-next-line @typescript-eslint/no-explicit-any
 setCircleIndicator?: React.Dispatch<React.SetStateAction<any>>;
 volume?: any;
 animationValue?: boolean;
 animationMarker?: boolean;
}

const FillingPhase: React.FC<FillingPhaseProps> = React.memo(({ setFilling, setCircleIndicator, allData, volume, animationValue = true, animationMarker = true }) => {
 const { GetCasesData } = useMyContext();
 const filling = GetCasesData == null ? null : GetCasesData[0]?.['filling_name'].replace('/upload', "");
 return (
  <>
   <h2>Filling Phase Graph</h2>
   <Suspense fallback={<div>Loading...4</div>}>

    <MarkerChart
     data={{
      dataPointsVoiding: [],
      dataPoints: allData?.[0],
      chartType: "Pves-1",
      condition: 1,
      heading1: "",
      heading2: "",
      animation: animationMarker
     }}
     setIndicator={setCircleIndicator}
    />
    <DynamicChart
     data={{
      dataPointsVoiding: [],
      dataPoints: allData?.[0],
      text: "Pves-1",
      chartType: "Pves-1",
      color: "rgb(0, 0, 255)",
      condition: 1,
      heading1: "cmH20",
      heading2: "Pves (1)",
      scale: false,
     }}
     animation={animationValue}
     renderFilling={setFilling}
    />
    <DynamicChart data={{
     dataPointsVoiding: [],
     dataPoints: allData?.[0],
     text: "Pabd-2",
     chartType: "Pabd-2",
     color: "rgb(255, 0, 0)",
     condition: 1,
     heading1: "cmH2O",
     heading2: "Pabd (2)",
     scale: false,
    }}
     animation={animationValue}
    />
    {/* <h3>Pdet</h3> */}
    <DynamicChart data={{
     dataPointsVoiding: [],
     dataPoints: allData?.[0],
     text: "Pdet",
     chartType: "Pdet",
     color: "rgb(46, 139, 87)",
     condition: 1,
     heading1: "cmH2O",
     heading2: "Pdet",
     scale: false,
    }}
     animation={animationValue}
    />
    <DynamicChart data={{
     dataPointsVoiding: [],
     dataPoints: allData?.[0],
     text: "Qura",
     chartType: "Qura",
     color: "rgb(46, 139, 87)",
     condition: 1,
     heading1: "ml/s",
     heading2: "Qura",
     scale: true,
    }}
     volumeStart={0}
     volumeEnd={volume ?? 700}
     animation={animationValue}
    />
   </Suspense>
   {filling !== null && (
    <>
     <Suspense fallback={<div>Loading...5</div>}>
      <DynamicTable fileName={filling} tableName='Filling Phase' buttonBoolean={false} />
      <SensationForm voiding={false} />
     </Suspense>
    </>
   )}
  </>
 );
});

export default FillingPhase;


