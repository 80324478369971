import React, { lazy, Suspense, useState } from 'react';
// import CheckTable from '../rtl/components/CheckTable';
// import voidingData from '../dataTables/variables/voidingData';
import { ButtonCom } from './Button';
import { useHistory } from 'react-router-dom';
import { useMyContext } from '../../../contexts/ContextProvider';
import { useToast } from '@chakra-ui/react'

const DynamicTable = lazy(() => import('./DynamicTable'));
// const SensationForm = lazy(() => import('./SensationForm'));
const DynamicChart = lazy(() => import('./DynamicChart'));
// const MarkerChart = lazy(() => import('./MarkerChart'));


interface DataItem {
  "Time-Calculated": number;
  "ML": number;
  "ML-Calculated": number;
  "Constant": number;
  "Time-(converted)": number;
  "Pves-1": number;
  "Pabd-2": number;
  "Pdet": number;
  "Qura": number;
  "Vmic": number;
  "Marker": number;
  "Marker-1": string;
  "Marker-2": string;
}
type ApiResponseData = DataItem[];
interface VoidingPhaseProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setCough?: React.Dispatch<React.SetStateAction<any>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setCircleIndicator?: React.Dispatch<React.SetStateAction<any>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ISetVoid?: boolean;
  allData: ApiResponseData
  animationValue?: boolean;
}

const VoidingPhase: React.FC<VoidingPhaseProps> = ({ ISetVoid, allData, animationValue = true }) => {
  const { GetCasesData, getFormFillBoolean, setFormFillBoolean } = useMyContext();
  const voiding1 = GetCasesData == null ? null : GetCasesData[0]?.['voiding1_name'].replace('/upload', "");

  const history = useHistory();
  const navigateToOtherPage = () => {
    history.push('/admin/new-report');
  };
  const [getVoid, setVoid] = useState(ISetVoid);
  const [getVoidingButton] = useState({
    className: "button-gap",
    label: "Voiding",
    disable: false
  });


  const [getReport, setReportButton] = useState({
    "className": "--blue",
    "label": "Waiting to Complete...",
    "disable": false,
  });

  const toast = useToast()
  const handleAsyncAction = async () => {
    const toastId = toast({
      title: 'Ask For Pee..',
      description: 'process begin',
      status: 'success',
      duration: 5000,
      isClosable: false,
    });

    try {
      await new Promise((resolve) => setTimeout(resolve, 2000));

      toast.update(toastId, {
        title: 'Starting',
        description: 'please wait..',
        status: 'info',
        duration: 5000,
      });
    } catch (error) {
      toast.update(toastId, {
        title: 'Error occurred!',
        status: 'error',
        duration: 5000,
      });
    }
  };
  const voidingPhaseFn = () => {
    handleAsyncAction();
    setTimeout(() => {
      setVoid(true);
      setReportButton({
        "className": "button-gap",
        "label": "Write-Report",
        "disable": true
      })
      setFormFillBoolean(false);
    }, 5000);
  }

  return (
    <>

      {getFormFillBoolean &&
        <ButtonCom
          className={getVoidingButton.className}
          label={getVoidingButton.label}
          handleClick={() => voidingPhaseFn()}
          disabled={getVoidingButton.disable}
        />
      }
      {getVoid && (
        <>
          <h2>Voiding Phase Graph</h2>
          <Suspense fallback={<div>Loading...6</div>}>
            {/* <MarkerChart
              data={{
                dataPointsVoiding: allData?.[1],
                dataPoints: [],
                chartType: "Pves-1",
                condition: 1,
                heading1: "",
                heading2: "",
                animation: animationMarker
              }}
            /> */}
            <DynamicChart
              data={{
                dataPointsVoiding: allData?.[1],
                dataPoints: [],
                text: "Pves (1)",
                chartType: "Pves-1",
                color: "rgb(0, 0, 255)",
                condition: 2,
                heading1: "cmH20",
                heading2: "Pves (1)",
                scale: false,
              }}
              animation={animationValue}
              renderVoiding={setVoid}
            />
            <DynamicChart data={{
              dataPointsVoiding: allData?.[1],
              dataPoints: [],
              text: "Pabd-2",
              chartType: "Pabd-2",
              color: "rgb(255, 0, 0)",
              condition: 2,
              heading1: "cmH2O",
              heading2: "Pabd (2)",
              scale: false,
            }}
              animation={animationValue}
            />
            <DynamicChart data={{
              dataPointsVoiding: allData?.[1],
              dataPoints: [],
              text: "Pdet",
              chartType: "Pdet",
              color: "rgb(46, 139, 87)",
              condition: 2,
              heading1: "cmH2O",
              heading2: "Pdet",
              scale: false,
            }}
              animation={animationValue}
            />
            <DynamicChart data={{
              dataPointsVoiding: allData?.[1],
              dataPoints: [],
              text: "Qura",
              chartType: "Qura",
              color: "rgb(46, 139, 87)",
              condition: 2,
              heading1: "ml/s",
              heading2: "Qura",
              scale: true,
            }}
              animation={animationValue}
            />
          </Suspense>
          {voiding1 &&
            <>
              <Suspense fallback={<div>Loading...7</div>}>
                <DynamicTable fileName={voiding1} tableName='Voiding Phase Result' buttonBoolean={false} />
                <br />
                <ButtonCom
                  className={getReport.className}
                  handleClick={navigateToOtherPage}
                  label={getReport.label}
                />
                {/* {getFormVoidBoolean &&
         <>
          
         </>
        } */}
                {/* <SensationForm voiding={true} /> */}
                {/* <DynamicTable fileName={"case1-voiding-table-result-2.json"} tableName='Marker Overview' /> */}
                {/* <CheckTable tableData={voidingData} /> */}

              </Suspense>
            </>
          }
        </>
      )}
    </>
  );
};

export default VoidingPhase;
