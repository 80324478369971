import { Box, Icon, SimpleGrid } from '@chakra-ui/react';
import MiniStatistics from '../../../components/card/MiniStatistics';
import IconBox from '../../../components/icons/IconBox';
import { MdAddTask, MdFileCopy, MdGroups, MdSummarize } from 'react-icons/md';
import { useCallback, useEffect, useState } from 'react';
import InstructorApiService from '../../../services/InstructorApiservices';
import AssignedCasesTable from './components/AssignedCasesTable';
import { useMyContext } from '../../../contexts/ContextProvider';
import React from 'react';

export default function UserReports() {
	const initialCasesState = {
		total_cases: 0,
		total_assigned: 0,
		total_in_progress: 0,
		total_review: 0,
		total_approved: 0,
		total_rejected: 0,
	};

	// Create a state variable and a function to update the state
	const [casesData, setCasesData] = useState(initialCasesState);
	const [assignedCasesData, setassignedCasesData] = useState([]);
	const {  setId, setName } = useMyContext();
	const [isLoading, setIsLoading] = useState(true);

	const fetchDataTable = useCallback(async (userId: any) => {
		try {
			const instructorApiService = new InstructorApiService();
			// eslint-disable-next-line prefer-const
			let result: any = await instructorApiService.getAllAssignedRecords(userId);
			result = JSON.parse(result)
			setassignedCasesData(result?.['data'] || []);
			const response = await instructorApiService.getAllCounts(userId);
			const resultData = JSON.parse(response);
			setCasesData(resultData?.["data"]);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	}, []);
	useEffect(() => {
		const fetchData = async () => {
			const storedLoginData = sessionStorage.getItem('loginData');
			const loginData = storedLoginData ? JSON.parse(storedLoginData) : null;
			if (loginData) {
				setName(loginData.name);
				setId(loginData.id);
				await fetchDataTable(loginData.id);
			} else {
				console.error('No login data found in sessionStorage');
			}
		};
		fetchData();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setId, setName]);
	// Chakra Color Mode
	return (
		<>
			<Box pt={{ base: '100px', md: '100px', xl: '100px' }}>
				<SimpleGrid columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }} gap='20px' mb='20px'>
					<MiniStatistics
						startContent={
							<IconBox
								w='56px'
								h='56px'
										bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
								icon={<Icon w='32px' h='32px' as={MdSummarize} color='white' />}
							/>
						}
						name='Total Cases'
						value={casesData.total_cases}
					/>
					<MiniStatistics
						startContent={
							<IconBox
								w='56px'
								h='56px'
										bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
								icon={<Icon w='32px' h='32px' as={MdGroups} color='blue' />}
							/>
						}
						name='Total Assigned'
						value={casesData.total_assigned}
					/>
					<MiniStatistics
						startContent={
							<IconBox
								w='56px'
								h='56px'
								bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
								icon={<Icon w='28px' h='28px' as={MdAddTask} color='orange' />}
							/>
						}
						name='Total In Progress'
						value={casesData.total_in_progress}
					/>
					<MiniStatistics
						startContent={
							<IconBox
								w='56px'
								h='56px'
										bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
								icon={<Icon w='32px' h='32px' as={MdFileCopy} color='yellow' />}
							/>
						}
						name='Total Review'
						value={casesData.total_review}
					/>
					<MiniStatistics
						startContent={
							<IconBox
								w='56px'
								h='56px'
										bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
								icon={<Icon w='32px' h='32px' as={MdFileCopy} color='green' />}
							/>
						}
						name='Total Approved'
						value={casesData.total_approved}
					/>
					<MiniStatistics
						startContent={
							<IconBox
								w='56px'
								h='56px'
										bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
								icon={<Icon w='32px' h='32px' as={MdFileCopy} color='red' />}
							/>
						}
						name='Total Rejected'
						value={casesData.total_rejected}
					/>
				</SimpleGrid>
				<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
					{isLoading ? <p>Loading...</p> : <AssignedCasesTable tableData={assignedCasesData} page={1} conditionAllreport={false}/>}
				</Box>
			</Box>
		</>
	);
}
