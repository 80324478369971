import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import parse from 'html-react-parser';
interface ViewReportModalProps {
  content: string;
  isOpen: boolean;
  onClose: () => void;
}

const ViewReportModal: React.FC<ViewReportModalProps> = ({ content, isOpen, onClose }) => {
  const [internalIsOpen, setInternalIsOpen] = useState(false);

  useEffect(() => {
    setInternalIsOpen(isOpen);
  }, [isOpen]);

  const closeModal = () => {
    setInternalIsOpen(false);
    onClose();
  };

  return (
    <div>
      <Modal isOpen={internalIsOpen} onClose={closeModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>View Remarks</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {parse(content!==undefined || content!==null ?content:"NA" )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={closeModal}>
              Close
            </Button>
            {/* <Button variant="ghost">Save</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ViewReportModal;
